html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: ivypresto-display, serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: initial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pagewrapper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
}

.contactwrapper {
  background-color: white;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s;
  overflow-x: hidden;
  margin: 0 81px;
}

.contacttext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 408px;
}

.contacttext h1 {
  font-weight: 400;
  font-size: 31px;
  line-height: 35px;
  max-width: 325px;
  margin: 20px 0;
}

.contacttext p {
  margin: 0;
  width: 262px;
  line-height: 18px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.contactbuttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 196px;
  width: 196px;
  border-radius: 100%;
  color: #517276;
  border: 1px solid #517276;
  border-style: dotted;
  margin: 0 39.5px;
}

.contactbutton.form {
  border: 1px solid white;
  border-style: dotted;
  margin: 30px 0;
  color: white;
}

.contactbutton p {
  margin: 3px 0;
  font-size: 20px;
  text-align: center;
}

.contactbutton:hover {
  border: 1px solid orange;
  cursor: pointer;
}

.footerwrapper {
  width: 100%;
  height: 595px;
  background-color: #517276;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footerblocktop {
  height: 278px;
  width: 85%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footerblockbottom {
  height: 218px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.footerblocktoptext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
}

.footerblocktoptext h3 {
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 51px;
  font-weight: 500;
  margin: 0;
  margin-bottom: -10px;
  letter-spacing: 0.7px;
}

.footerblocktoptext a {
  font-size: 25px;
  line-height: 32px;
  color: white;
  text-decoration: none;
  margin: 4px 0;
}

.footerblocktoptext p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 45px;
}

.footerblockbottomlogocontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  color: white;
  margin-left: 86px;
  height: 100%;
}

.footerblockbottomlogocontainer img {
  width: 65px;
  height: 100px;
  object-fit: contain;
}

.footerblockbottomlogocontainer p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 200;
  margin-top: 20px;
}

.footerbottomlinks {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.footerbottomlinks p {
  text-decoration: underline;
  color: white;
  margin: 0 10px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 51px;
}

.contactformular {
  width: 80vw;
  height: 100vh;
  background-color: #2b4b4e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 80px;
}

.headerwrapper {
  height: 100vh;
  background: url("./images/headerbackground.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
}

.mobilelogo {
  display: none;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 20px;
  position: relative;
}

.navigation img {
  width: 63px;
  position: absolute;
  left: 80px;
  top: 20px;
}

.navigation a {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 51px;
  color: #000000;
  text-decoration: none;
  letter-spacing: 0.7px;
  margin: 0 45px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.navigation a:last-child {
  border-radius: 30px;
  background-color: #517276;
  color: #ffffff;
  height: 49px;
  padding: 0 20px;
}

.headermain {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headertitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: -50px;
}

.headertitle h2 {
  font-size: 60px;
  line-height: 51px;
  text-align: center;
  font-weight: 400;
  margin: 0;
}

.headertitle h2 span {
  font-size: 60px;
  line-height: 51px;
  font-style: italic;
  text-transform: capitalize;
  font-family: ivypresto-display, serif;
}

.headertitle span {
  font-size: 16px;
  line-height: 51px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  letter-spacing: 0.7px;
}

.headertitle a {
  margin-top: 50px;
  color: white;
  letter-spacing: 0.7px;
  border: 1px solid white;
  padding: 10px 30px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transition: all 0.5s;
}

.headertitle a:hover {
  background-color: white;
  color: #547579;
}

.contentblock {
  background-color: #517276;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 170px;
  padding-bottom: 370px;
}

.contenttitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: white;
}

.contenttitle p {
  margin: 0;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.7px;
}

.contenttitle h1 {
  font-weight: 400;
  font-size: 60px;
  margin: 0;
}

.contentmain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
  width: 80%;
  color: white;
}

.content1 {
  width: 237px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.content2::-webkit-scrollbar {
  display: none;
}

.content2 {
  width: 367px;
  display: flex;
  align-items: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: scroll;
  position: relative;
}

.content2 img {
  width: 90%;
  height: 95%;
  margin: 0 10px;
  object-fit: cover;
}

.slidericon {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  right: 0;
  z-index: 1;
}

.slidericon.second {
  left: 0;
}

.slidericon span {
  color: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #2b4b4e;
  border-style: dotted;
}

.slidericon span:hover {
  color: orange;
  border: 1px solid orange;
  border-style: dotted;
  cursor: pointer;
}

.schliessenicon {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: 40px;
  top: 10px;
}

.schliessenicon:hover {
  cursor: pointer;
}

.schliessenicon.impressum {
  color: white;
}

.slidericon.impressum {
  background-color: transparent;
  position: relative;
  border: 1px solid white;
  border-style: dotted;
  padding: 0;
  margin-left: 10px;
  width: 25px;
  height: 25px;
  transition: all 0.5s;
}

.slidericon.form {
  background-color: transparent;
  position: relative;
  border: 1px solid white;
  border-style: dotted;
  padding: 0;
  margin-left: 10px;
  width: 25px;
  height: 25px;
  transition: all 0.5s;
}

.schliessenicon:hover > .slidericon.form {
  background-color: #d3dedd;
  color: #517276;
}

.content3 {
  width: 300px;
}

.content1block {
  width: 250px;
}

.content1block img {
  transition: all 0.4s;
}

.content1block:hover > img {
  transform: rotate(25deg);
}

.content1block:nth-child(2):hover > img {
  margin-top: -10px;
  margin-bottom: 10px;
  transform: none;
}

.content1block h2 {
  font-weight: 400;
  font-size: 26px;
  margin: 5px 0;
}

.content1block p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.content1 .content1block:nth-child(1) {
  margin-top: -250px;
}

.content1 .content1block:nth-child(2) {
  margin-bottom: -50px;
  margin-left: 100px;
}

.content1 .content1block:nth-child(3) {
  margin-bottom: -550px;
  margin-left: 700px;
}

.content3 p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.content3 p:nth-child(1) {
  font-weight: 400;
}

.contactinformation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  margin-left: 50px;
  height: 100%;
}

.contactinformation h1 {
  font-weight: 400;
  margin: 0;
}

.contactinformation label {
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 20px 0;
  letter-spacing: 0.7px;
  font-size: 14px;
}

.contactinformation label p {
  margin: 5px 0;
  font-family: ivypresto-display, serif;
  text-transform: lowercase;
  font-size: 20px;
  line-height: 28px;
}

.contactform {
  display: flex;
  flex-direction: column;
  width: 550px;
  color: white;
  margin-right: 50px;
}

.contactform h1 {
  font-size: 36px;
  color: white;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
}

.contactform p {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
}

.contactform form {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.contactform form input {
  width: 250px;
  height: 31px;
  background-color: transparent;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #cbd3d4;
  color: #d3dedd;
  font-size: 16px;
  font-style: italic;
}

.contactform form input:focus {
  outline: none;
}

.contactform form input::placeholder {
  color: #d3dedd;
}

.contactform form textarea {
  width: 100%;
  font-size: 16px;
  background-color: #355558;
  color: #d3dedd;
  border: none;
  resize: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-top: 30px;
  padding: 20px;
}

.contactform form textarea::placeholder {
  color: #d3dedd;
}

.contactform form textarea:focus {
  outline: none;
}

.accept {
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0;
}

.accept p {
  font-size: 11px;
  line-height: 17px;
  color: #d3dedd;
  margin: 0;
}

.contactform form button {
  color: white;
  border: 1px solid white;
  background-color: transparent;
  padding: 10px 30px;
  border-radius: 30px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transition: all 0.5s;
  margin-top: 15px;
  letter-spacing: 0.7px;
  font-weight: 500;
}

.contactform form button:hover {
  cursor: pointer;
  background-color: white;
  color: #2b4b4e;
}

.accept input {
  margin: 0 !important;
  margin-right: 10px !important;
  width: 50px !important;
  background-color: #d3dedd;
  border: 1px solid #707070;
}

.backicon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid white;
  border-style: dotted;
  padding: 5px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.backicon.menu {
  position: relative;
  top: initial;
  right: initial;
  z-index: 4;
}

.mobilenav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #517276;
  opacity: 1;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}

.mobilenav a {
  color: white;
  margin: 20px 0;
  font-size: 30px;
  text-transform: uppercase;
}

.brakemobile {
  display: none;
}

.mobilenav.closed {
  opacity: 0;
  z-index: -1;
}

.mobilenav img {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 80px;
}

.socials {
  position: absolute;
  bottom: 20px;
}

.burger {
  display: none;
}

.overlay {
  height: 100vh;
  width: 100vw;
  background-color: #517276;
  z-index: 10;
  overflow-y: scroll;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.overlay.hidden {
  display: none;
}

.overlay .innertextdiv {
  width: 80%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  position: relative;
  word-break: break-word;
  padding: 100px 0;
}

.overlay h1 {
  color: white;
  font-family: ivypresto-display, serif;
}

.overlay h2 {
  color: white;
  font-family: ivypresto-display, serif;
}

.overlay h3 {
  color: white;
  font-family: ivypresto-display, serif;
}

.overlay h4 {
  color: white;
  font-family: ivypresto-display, serif;
}

.overlay p {
  color: white;
  line-height: 24px;
}

.overlay li {
  color: white;
}

.overlay a {
  color: #8dafac;
}

.footerbottomlinks p:hover {
  cursor: pointer;
}
/* Mobile version */

@media (max-width: 1350px) {
  .contentblock {
    background-color: #517276;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 30px;
  }
  .contentmain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    flex-direction: column;
    /* width: 80%; */
    color: white;
  }
  .contenttitle p {
    margin: 10px 0;
  }
  .content1 {
    width: auto;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    flex-direction: column;
  }
  .content2 {
    width: 100%;
    height: 350px;
  }
  .content3 {
    width: auto;
  }
  .content1 .content1block:nth-child(1) {
    margin-top: 60px;
  }
  .content1 .content1block:nth-child(2) {
    margin-bottom: 0;
    margin-left: 0;
  }
  .content1 .content1block:nth-child(3) {
    margin-bottom: 0;
    margin-left: 0;
  }
  .content1block {
    margin: 25px 0 !important;
  }
  .contenttitle h1 {
    line-height: 54px;
    margin-top: 25px;
  }
  .contactwrapper {
    height: auto;
    flex-direction: column;
    margin: 0 30px;
    align-items: center;
    justify-content: center;
  }
  .contacttext {
    width: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
  }
  .contactbuttons {
    flex-direction: column;
    margin: 20px 0;
  }
  .contactbutton {
    margin: 15px 0;
  }
  .footerwrapper {
    height: auto;
    width: auto;
    padding: 50px 30px;
  }
  .footerblocktop {
    height: auto;
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footerblocktoptext {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px 0;
  }
  .footerblockbottom {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .footerblockbottomlogocontainer {
    margin: 0;
    align-items: center;
    justify-content: center;
  }
  .footerbottomlinks {
    position: relative;
    left: initial;
    transform: initial;
  }
  .contactformular {
    height: auto;
    flex-direction: column;
    padding: 50px 20px;
  }
  .contactinformation {
    margin-left: 0;
    align-items: center;
    justify-content: center;
  }
  .contactform {
    flex-direction: column;
    width: auto;
    margin-right: 0;
    align-items: center;
    justify-content: center;
  }
  .contactform form {
    flex-direction: column;
    width: 100%;
  }
  .contactform form textarea {
    width: 85%;
  }
  .contactform h1 {
    text-align: center;
    font-size: 32px;
    margin-top: 10px;
  }
  .contactinformation h1 {
    text-align: center;
  }
  .contactinformation label {
    width: 100%;
  }
  .contactform form input {
    width: 100%;
  }
  .navigation {
    display: none;
  }
  .burger {
    display: flex;
    position: absolute;
    right: 40px;
    top: 30px;
  }
  .burger.moved {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .mobilelogo {
    display: flex;
    height: 80px;
    width: 52px;
    margin-left: 20px;
    margin-top: 20px;
  }
  .mobilelogo img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .brakemobile {
    display: flex;
  }
  .headerwrapper {
    background: url("./images/mobilebackground.png");
    background-position: center;
    background-size: cover;
  }
}

.cookiediv {
  background-color: green;
}
